import * as T from '../utils/TypeUtil';
import { v4 as uuid } from 'uuid';
import { EMartialStatus, ERelationshipType } from '../AppEnums';
import moment from 'moment';
import { FormatSsn } from '../utils/DataFormatUtil';

export const EElementType = {
    AppUser: 'AppUser',
    AppUserActivity: 'AppUserActivity',

    Attachment: 'Attachment',

    User: 'User',
    UserUserRel: 'UserUserRel',
    UserAddress: 'UserAddress',
    UserNote: 'UserNote',

    Policy: 'Policy',
    PolicyNote: 'PolicyNote',
    PolicyMember: 'PolicyMember',

    Task: 'Task',
    TaskComment: 'TaskComment',

    ViewSettings: 'ViewSettings',
    Setting: 'Setting',
    Carrier: 'Carrier'
}

export const ESysTaskType = {
    CREATE_POLICY: 'Create Policy'
}

export const EPersonFields = {
    Id: 'Id',

    FirstName: 'FirstName',
    LastName: 'LastName',
    MiddleName: 'MiddleName',
    Suffix: 'Suffix',
    NickName: 'NickName',

    PhoneNumber: 'PhoneNumber',
    AltPhoneNumber: 'AltPhoneNumber',
    Email: 'Email',
    DOB: 'DOB',

    HomeAddress: 'HomeAddress',
    HomeState: 'HomeState',
    MailAddress: 'MailAddress',

    Ssn4: 'Ssn4',
    SsnHash: 'SsnHash',

    // Details
    Company: 'Company',
    Gender: 'Gender',
    MaritalStatus: 'MaritalStatus',

    DriverLicense: 'DriverLicense',
    ImmigrationStatus: 'ImmigrationStatus',

    IsPolicyOwner: 'IsPolicyOwner',
    DNC: 'DNC',

    // Rels
    Addresses: 'Addresses',
};

export const EPersonFieldLabels = {
    ...EPersonFields,
    FirstName: 'First Name',
    LastName: 'Last Name',
    MiddleName: 'Middle Name',
    NickName: 'Nick Name',
    PhoneNumber: 'Phone',
    AltPhoneNumber: 'Alt. Phone',

    HomeAddress: 'Home Address',
    HomeState: 'Home State',
    MailAddress: 'Mailing Address',

    MaritalStatus: 'Status',
    DriverLicense: 'DL',
    ImmigrationStatus: 'Citizenship',
    Ssn4: 'SSN',
    SsnHash: 'SSN',
    IsPolicyOwner: 'Policy Owner',
    DNC: 'Do Not Call',
};

export const PersonFieldInfos = {
    [EPersonFields.FirstName]: { label: EPersonFieldLabels.FirstName, name: EPersonFields.FirstName, required: true, length: 50 },
    [EPersonFields.LastName]: { label: EPersonFieldLabels.LastName, name: EPersonFields.LastName, required: true, length: 50 },
    [EPersonFields.MiddleName]: { label: EPersonFieldLabels.MiddleName, name: EPersonFields.MiddleName, length: 50 },
    [EPersonFields.NickName]: { label: EPersonFieldLabels.NickName, name: EPersonFields.NickName, length: 50 },
    [EPersonFields.Suffix]: { label: EPersonFieldLabels.Suffix, name: EPersonFields.Suffix },
    [EPersonFields.Company]: { label: EPersonFieldLabels.Company, name: EPersonFields.Company, length: 100 },
    [EPersonFields.PhoneNumber]: { label: EPersonFieldLabels.PhoneNumber, name: EPersonFields.PhoneNumber, required: false },
    [EPersonFields.AltPhoneNumber]: { label: EPersonFieldLabels.AltPhoneNumber, name: EPersonFields.AltPhoneNumber, required: false },
    [EPersonFields.Email]: { label: EPersonFieldLabels.Email, name: EPersonFields.Email, length: 100 },
    [EPersonFields.DOB]: { label: EPersonFieldLabels.DOB, name: EPersonFields.DOB, required: true },
    [EPersonFields.Gender]: { label: EPersonFieldLabels.Gender, name: EPersonFields.Gender, required: true },
    [EPersonFields.MaritalStatus]: { label: EPersonFieldLabels.MaritalStatus, name: EPersonFields.MaritalStatus, required: true },
    [EPersonFields.Ssn4]: { label: EPersonFieldLabels.Ssn4, name: EPersonFields.Ssn4, readOnly: true },
    [EPersonFields.SsnHash]: { label: EPersonFieldLabels.SsnHash, name: EPersonFields.SsnHash, readOnly: false },
    [EPersonFields.DriverLicense]: { label: EPersonFieldLabels.DriverLicense, name: EPersonFields.DriverLicense, length: 50 },
    [EPersonFields.DNC]: { label: EPersonFieldLabels.DNC, name: EPersonFields.DNC },
    [EPersonFields.ImmigrationStatus]: { label: EPersonFieldLabels.ImmigrationStatus, name: EPersonFields.ImmigrationStatus },
};

export const EPersonRelFields = {
    Id: 'Id',
    UserId: 'UserId',
    RelatedUserId: 'RelatedUserId',
    RelType: 'RelType',
    RelatedUserEntity: 'RelatedUserEntity',
};

export const EPersonRelFieldLabels = {
    ...EPersonRelFields,
    ...{
        RelType: 'Relationship',
    }
};

export const EUserNoteField = {
    Id: 'Id',
    UserId: 'UserId',
    CreatedById: 'CreatedById',
    CreatedBy: 'CreatedBy',
    CreatedOn: 'CreatedOn',
    Note: 'Note',
}

export const EUserNoteFieldLabel = {
    ...EUserNoteField,
    ...{
        CreatedBy: 'Created By',
        CreatedOn: 'Created On',
        Note: 'Note',
    }
}

export const EUserAddressFields = {
    Id: 'Id',
    Type: 'Type',
    UserId: 'UserId',
    Address: 'Address',
    Address2: 'Address2',
    Unit: 'Unit',
    City: 'City',
    State: 'State',
    Zip: 'Zip',
    County: 'County',
}

export const UserAddressFieldInfos = {
    [EUserAddressFields.Type]: { label: EUserAddressFields.Type, name: EUserAddressFields.Type, required: true },

    [EUserAddressFields.Address]: { label: EUserAddressFields.Address, name: EUserAddressFields.Address, required: true, length: 100 },
    [EUserAddressFields.Address2]: { label: EUserAddressFields.Address2, name: EUserAddressFields.Address2, required: false, length: 100 },

    [EUserAddressFields.Unit]: { label: EUserAddressFields.Unit, name: EUserAddressFields.Unit, required: false, length: 50 },

    [EUserAddressFields.City]: { label: EUserAddressFields.City, name: EUserAddressFields.City, required: true },
    [EUserAddressFields.State]: { label: EUserAddressFields.State, name: EUserAddressFields.State, required: true },

    [EUserAddressFields.County]: { label: EUserAddressFields.County, name: EUserAddressFields.County, required: false },
    [EUserAddressFields.Zip]: { label: EUserAddressFields.Zip, name: EUserAddressFields.Zip, required: true, length: 5 },
}

export const EPolicyFields = {
    Id: 'Id',
    Type: 'Type',
    Category: 'Category',

    Priority: 'Priority',

    Designator: 'Designator',
    AcaFfmId: 'AcaFfmId',
    AcaFfmSubscriberId: 'AcaFfmSubscriberId',
    AOR: 'AOR',
    Npn: 'Npn',
    DNR: 'DNR',
    OtherAgent: 'OtherAgent',
    OtherAgentName: 'OtherAgentName',
    ConsentReceived: 'ConsentReceived',
    DmiAddressed: 'DmiAddressed',
    PremiumPaid: 'PremiumPaid',
    Income: 'Income',

    OwnerId: 'OwnerId',
    OwnerFirstName: 'OwnerFirstName',
    OwnerLastName: 'OwnerLastName',
    OwnerMiddleName: 'OwnerMiddleName',
    OwnerSuffix: 'OwnerSuffix',
    OwnerNickName: 'OwnerNickName',
    OwnerPhoneNumber: 'OwnerPhoneNumber',
    OwnerAltPhoneNumber: 'AltOwnerPhoneNumber',
    OwnerEmail: 'OwnerEmail',

    OwnerHomeAddress: 'OwnerHomeAddress',
    OwnerHomeState: 'OwnerHomeState',
    OwnerMailAddress: 'OwnerMailAddress',

    OwnerDOB: 'OwnerDOB',
    OwnerSsn4: 'OwnerSsn4',
    OwnerSsnHash: 'OwnerSsnHash',

    CarrierId: 'CarrierId',
    PlanName: 'PlanName',
    PolicyNumber: 'PolicyNumber',

    EffectiveDate: 'EffectiveDate',
    ExpirationDate: 'ExpirationDate',

    State: 'State',
    Status: 'Status',

    CommissionType: 'CommissionType',
    CommissionPaid: 'CommissionPaid',
    Premium: 'Premium',
    NetPremium: 'NetPremium',

    Agent: 'Agent',
    AgentId: 'AgentId',

    CreatedBy: 'CreatedBy',
    CreatedOn: 'CreatedOn',

    UpdatedBy: 'UpdatedBy',
    UpdatedOn: 'UpdatedOn',

    ReferredBy: 'ReferredBy',
    ReferredById: 'ReferredById',
    ReferredOn: 'ReferredOn',

    ProcessedBy: 'ProcessedBy',
    ProcessedById: 'ProcessedById',
    ProcessedOn: 'ProcessedOn',

    MonitoredBy: 'MonitoredBy',
    MonitoredById: 'MonitoredById',

    VerifiedBy: 'VerifiedBy',
    VerifiedById: 'VerifiedById',
    VerifiedOn: 'VerifiedOn',

    RenewEffectiveDate: 'RenewEffectiveDate',

    // IsDeleted: 'IsDeleted',

    Members: 'Members',
};

export const EPolicyFieldLabels = {
    ...EPolicyFields,
    ...{
        Status: 'Status (External)',

        AcaFfmId: 'FFM ID',
        AcaFfmSubscriberId: 'FFM Subscriber ID',
        Income: 'Household Income ($)',
        Npn: 'NPN (Health Sherpa)',
        AOR: 'AOR (Health Sherpa)',
        DNR: 'Do Not Renew',
        OtherAgent: 'Other Agent',
        OtherAgentName: 'Other Agent Name',
        ConsentReceived: 'Consent Received',
        PremiumPaid: 'Premium Paid',
        DmiAddressed: 'DMI Completed',

        OwnerId: 'Policy Owner',
        OwnerFirstName: 'Owner First',
        OwnerLastName: 'Owner Last',
        OwnerMiddleName: 'Owner Middle',
        OwnerSuffix: 'Owner Suffix',
        OwnerNickName: 'Owner Nick',
        OwnerPhoneNumber: 'Owner Phone',
        OwnerAltPhoneNumber: 'Owner Alt Phone',
        OwnerEmail: 'Owner Email',
        OwnerDOB: 'Owner DOB',

        OwnerHomeAddress: 'Owner Home Address',
        OwnerHomeState: 'Owner Home State',
        OwnerMailAddress: 'Owner Mailing Address',

        OwnerSsn4: 'Owner SSN',

        PlanName: 'Plan',
        PolicyNumber: 'Policy Number',
        CarrierId: 'Carrier',

        EffectiveDate: 'Effective Date',
        ExpirationDate: 'Expiration Date',

        CommissionType: 'Comm. Type',
        CommissionPaid: 'Comm. Paid',
        Premium: 'Premium ($)',
        NetPremium: 'Net Premium ($)',

        ReferredBy: 'Referrer',
        ReferredOn: 'Referred On',

        ProcessedBy: 'Processor',
        ProcessedOn: 'Processed On',

        Agent: 'Agent',
        AgentId: 'Agent',

        MonitoredBy: 'Monitor',
        MonitoredById: 'Monitor',

        VerifiedById: 'Verifier',
        VerifiedBy: 'Verifier',
        VerifiedOn: 'VerifiedOn',

        CreatedBy: 'Creator',
        CreatedOn: 'Created On',
        UpdatedBy: 'Updated By',
        UpdatedOn: 'Updated On',

        RenewEffectiveDate: 'Renew Effective Date',

        // IsDeleted: 'Deleted',
    }
};

export const PolicyFieldInfos = {
    [EPolicyFields.Type]: { label: EPolicyFieldLabels.Type, name: EPolicyFields.Type, required: true },
    [EPolicyFields.Category]: { label: EPolicyFieldLabels.Category, name: EPolicyFields.Category, required: true },

    [EPolicyFields.Priority]: { label: EPolicyFieldLabels.Priority, name: EPolicyFields.Priority, required: true },

    [EPolicyFields.Designator]: { label: EPolicyFieldLabels.Designator, name: EPolicyFields.Designator, required: false, length: 50 },
    [EPolicyFields.AcaFfmId]: { label: EPolicyFieldLabels.AcaFfmId, name: EPolicyFields.AcaFfmId, required: false, length: 20 },
    [EPolicyFields.AcaFfmSubscriberId]: { label: EPolicyFieldLabels.AcaFfmSubscriberId, name: EPolicyFields.AcaFfmSubscriberId, required: false, length: 20 },
    [EPolicyFields.Income]: { label: EPolicyFieldLabels.Income, name: EPolicyFields.Income, required: false },
    [EPolicyFields.AOR]: { label: EPolicyFieldLabels.AOR, name: EPolicyFields.AOR, required: false, length: 32 },
    [EPolicyFields.Npn]: { label: EPolicyFieldLabels.Npn, name: EPolicyFields.Npn, required: false, length: 32 },
    [EPolicyFields.DNR]: { label: EPolicyFieldLabels.DNR, name: EPolicyFields.DNR },
    [EPolicyFields.OtherAgent]: { label: EPolicyFieldLabels.OtherAgent, name: EPolicyFields.OtherAgent },
    [EPolicyFields.OtherAgentName]: { label: EPolicyFieldLabels.OtherAgentName, name: EPolicyFields.OtherAgentName, showLabel: false },
    [EPolicyFields.ConsentReceived]: { label: EPolicyFieldLabels.ConsentReceived, name: EPolicyFields.ConsentReceived },
    [EPolicyFields.DmiAddressed]: { label: EPolicyFieldLabels.DmiAddressed, name: EPolicyFields.DmiAddressed },
    [EPolicyFields.PremiumPaid]: { label: EPolicyFieldLabels.PremiumPaid, name: EPolicyFields.PremiumPaid },

    [EPolicyFields.OwnerId]: { label: EPolicyFieldLabels.OwnerId, name: EPolicyFields.OwnerId, required: true, readOnly: true },

    [EPolicyFields.CarrierId]: { label: EPolicyFieldLabels.CarrierId, name: EPolicyFields.CarrierId, required: false },
    [EPolicyFields.PlanName]: { label: EPolicyFieldLabels.PlanName, name: EPolicyFields.PlanName, required: false, length: 500 },
    [EPolicyFields.PolicyNumber]: { label: EPolicyFieldLabels.PolicyNumber, name: EPolicyFields.PolicyNumber, required: false, length: 20 },

    [EPolicyFields.EffectiveDate]: { label: EPolicyFieldLabels.EffectiveDate, name: EPolicyFields.EffectiveDate, required: true },
    [EPolicyFields.ExpirationDate]: { label: EPolicyFieldLabels.ExpirationDate, name: EPolicyFields.ExpirationDate, required: false },

    [EPolicyFields.RenewEffectiveDate]: { label: EPolicyFieldLabels.RenewEffectiveDate, name: EPolicyFields.RenewEffectiveDate },

    [EPolicyFields.State]: { label: EPolicyFieldLabels.State, name: EPolicyFields.State, required: true },
    [EPolicyFields.Status]: { label: EPolicyFieldLabels.Status, name: EPolicyFields.Status },

    [EPolicyFields.CommissionType]: { label: EPolicyFieldLabels.CommissionType, name: EPolicyFields.CommissionType, required: false },
    [EPolicyFields.CommissionPaid]: { label: EPolicyFieldLabels.CommissionPaid, name: EPolicyFields.CommissionPaid, required: false },
    [EPolicyFields.Premium]: { label: EPolicyFieldLabels.Premium, name: EPolicyFields.Premium, required: false },
    [EPolicyFields.NetPremium]: { label: EPolicyFieldLabels.NetPremium, name: EPolicyFields.NetPremium, required: false },

    [EPolicyFields.AgentId]: { label: EPolicyFieldLabels.AgentId, name: EPolicyFields.AgentId, required: false },
    [EPolicyFields.Agent]: { label: EPolicyFieldLabels.Agent, name: EPolicyFields.Agent, required: false },

    [EPolicyFields.ReferredById]: { label: EPolicyFieldLabels.ReferredBy, name: EPolicyFields.ReferredById, required: false },
    [EPolicyFields.ReferredBy]: { label: EPolicyFieldLabels.ReferredBy, name: EPolicyFields.ReferredBy, required: false },
    [EPolicyFields.ReferredOn]: { label: EPolicyFieldLabels.ReferredOn, name: EPolicyFields.ReferredOn, required: false },

    [EPolicyFields.ProcessedById]: { label: EPolicyFieldLabels.ProcessedBy, name: EPolicyFields.ProcessedById, required: false },
    [EPolicyFields.ProcessedOn]: { label: EPolicyFieldLabels.ProcessedOn, name: EPolicyFields.ProcessedOn, required: false },

    [EPolicyFields.MonitoredById]: { label: EPolicyFieldLabels.MonitoredBy, name: EPolicyFields.MonitoredById, required: false },
    [EPolicyFields.MonitoredBy]: { label: EPolicyFieldLabels.MonitoredBy, name: EPolicyFields.MonitoredBy, required: false },

    [EPolicyFields.VerifiedById]: { label: EPolicyFieldLabels.VerifiedBy, name: EPolicyFields.VerifiedById, required: false },
    [EPolicyFields.VerifiedBy]: { label: EPolicyFieldLabels.VerifiedBy, name: EPolicyFields.VerifiedBy, required: false },
    [EPolicyFields.VerifiedOn]: { label: EPolicyFieldLabels.VerifiedOn, name: EPolicyFields.VerifiedOn, required: false },

    [EPolicyFields.CreatedBy]: { label: EPolicyFieldLabels.CreatedBy, name: EPolicyFields.CreatedBy, required: false },
    [EPolicyFields.CreatedOn]: { label: EPolicyFieldLabels.CreatedOn, name: EPolicyFields.CreatedOn, required: false },
    [EPolicyFields.UpdatedBy]: { label: EPolicyFieldLabels.UpdatedBy, name: EPolicyFields.UpdatedBy, required: false },
    [EPolicyFields.UpdatedOn]: { label: EPolicyFieldLabels.UpdatedOn, name: EPolicyFields.UpdatedOn, required: false },

    // [EPolicyFields.IsDeleted]: { label: EPolicyFieldLabels.IsDeleted, name: EPolicyFields.IsDeleted },
};

export const EPolicyMemberFields = {
    Id: 'Id',
    PolicyId: 'PolicyId',
    MemberId: 'MemberId',
    Member: 'Member',
    Apply: 'Apply'
}

export const EPolicyNoteFields = {
    Id: 'Id',
    PolicyId: 'PolicyId',
    CreatedById: 'CreatedById',
    CreatedBy: 'CreatedBy',
    CreatedOn: 'CreatedOn',
    Note: 'Note',
}

export const EPolicyNoteFieldLabels = {
    ...EPolicyNoteFields,
    ...{
        CreatedBy: 'Created By',
        CreatedOn: 'Created On',
        Note: 'Note',
    }
}

export const ETaskFields = {
    Id: 'Id',
    Name: 'Name',
    Note: 'Note',
    SystemTaskType: 'SystemTaskType',
    IsOpen: 'IsOpen',
    CompletedOn: 'CompletedOn',

    CreatedOn: 'CreatedOn',
    CreatedById: 'CreatedById',
    CreatedBy: 'CreatedBy',

    AssigneeId: 'AssigneeId',
    Assignee: 'Assignee',

    PolicyId: 'PolicyId',
    PolicyName: 'PolicyName',
    PolicyOwnerId: 'PolicyOwnerId',
    PolicyAcaFfmId: 'PolicyAcaFfmId',

    UserId: 'UserId',

    TargetUserLastName: 'TargetUserLastName',
    TargetUserFirstName: 'TargetUserFirstName',
    TargetUserMiddleName: 'TargetUserMiddleName',
    TargetUserSuffix: 'TargetUserSuffix',
    TargetUserNickName: 'TargetUserNickName',
    TargetUserDOB: 'TargetUserDOB',
    TargetUserSsn4: 'TargetUserSsn4',

    Comments: 'Comments',

    // IsDeleted: 'IsDeleted',
}
export const ETaskFieldLabels = {
    ...ETaskFields,
    ...{
        IsOpen: 'Open',
        Note: 'Description',
        SystemTaskType: 'Auto-generate task type',

        AssigneeId: 'Assignee',

        CompletedOn: 'Completed On',
        CreatedOn: 'Created On',

        CreatedBy: 'Created By',

        PolicyName: 'Policy Name',
        PolicyAcaFfmId: 'Policy ACA FFM ID',

        TargetUserLastName: 'Contact Last',
        TargetUserFirstName: 'Contact First',
        TargetUserMiddleName: 'Contact Middle',
        TargetUserSuffix: 'Contact Suffix',
        TargetUserNickName: 'Contact Nick',
        TargetUserDOB: 'Contact DOB',
        TargetUserSsn4: 'Contact SSN',

        // IsDeleted: 'Deleted',
    }
}
export const TaskFieldInfos = {
    [ETaskFields.Name]: { name: ETaskFields.Name, label: ETaskFieldLabels.Name, required: true, length: 256 },
    [ETaskFields.Note]: { name: ETaskFields.Note, label: ETaskFieldLabels.Note, required: true, length: 5000 },
    [ETaskFields.SystemTaskType]: { name: ETaskFields.SystemTaskType, label: ETaskFieldLabels.SystemTaskType, length: 100 },
    [ETaskFields.IsOpen]: { name: ETaskFields.IsOpen, label: ETaskFieldLabels.IsOpen },
    [ETaskFields.CompletedOn]: { name: ETaskFields.CompletedOn, label: ETaskFieldLabels.CompletedOn },
    [ETaskFields.CreatedOn]: { name: ETaskFields.CreatedOn, label: ETaskFieldLabels.CreatedOn },

    [ETaskFields.CreatedById]: { name: ETaskFields.CreatedById, label: ETaskFieldLabels.CreatedById, readOnly: true },
    [ETaskFields.CreatedBy]: { name: ETaskFields.CreatedBy, label: ETaskFieldLabels.CreatedBy, readOnly: true },

    [ETaskFields.AssigneeId]: { name: ETaskFields.AssigneeId, label: ETaskFieldLabels.AssigneeId },
    [ETaskFields.Assignee]: { name: ETaskFields.Assignee, label: ETaskFieldLabels.Assignee },

    [ETaskFields.PolicyId]: { name: ETaskFields.PolicyId, label: ETaskFieldLabels.PolicyId },
    [ETaskFields.PolicyType]: { name: ETaskFields.PolicyType, label: ETaskFieldLabels.PolicyType },
    [ETaskFields.PolicyDesignator]: { name: ETaskFields.PolicyDesignator, label: ETaskFieldLabels.PolicyDesignator },
    [ETaskFields.PolicyAcaFfmId]: { name: ETaskFields.PolicyAcaFfmId, label: ETaskFieldLabels.PolicyAcaFfmId },

    [ETaskFields.UserId]: { name: ETaskFields.UserId, label: ETaskFieldLabels.UserId },

    [ETaskFields.TargetUserLastName]: { name: ETaskFields.TargetUserLastName, label: ETaskFieldLabels.TargetUserLastName },
    [ETaskFields.TargetUserFirstName]: { name: ETaskFields.TargetUserFirstName, label: ETaskFieldLabels.TargetUserFirstName },
    [ETaskFields.TargetUserMiddleName]: { name: ETaskFields.TargetUserMiddleName, label: ETaskFieldLabels.TargetUserMiddleName },
    [ETaskFields.TargetUserSuffix]: { name: ETaskFields.TargetUserSuffix, label: ETaskFieldLabels.TargetUserSuffix },
    [ETaskFields.TargetUserNickName]: { name: ETaskFields.TargetUserNickName, label: ETaskFieldLabels.TargetUserNickName },
    [ETaskFields.TargetUserDOB]: { name: ETaskFields.TargetUserDOB, label: ETaskFieldLabels.TargetUserDOB },
    [ETaskFields.TargetUserSsn4]: { name: ETaskFields.TargetUserSsn4, label: ETaskFieldLabels.TargetUserSsn4 },
}

export const ETaskCommentFields = {
    Id: 'Id',
    TaskId: 'TaskId',
    Note: 'Note',
    CreatedById: 'CreatedById',
    CreatedBy: 'CreatedBy',
    CreatedOn: 'CreatedOn',
}
export const ETaskCommentFieldLabels = {
    ...ETaskCommentFields,
    ...{
        CreatedBy: 'Created By',
        CreatedOn: 'Created On',
    }
}

export const EAttachmentField = {
    Id: 'Id',
    Name: 'Name',
    ContentType: 'ContentType',

    TargetUserFullName: 'TargetUserFullName',
    TargetUserNickName: 'TargetUserNickName',
    TargetUserDOB: 'TargetUserDOB',
    TargetUserSsn4: 'TargetUserSsn4',

    AssociatedUserId: 'AssociatedUserId',

    AssociatedPolicyId: 'AssociatedPolicyId',
    AssociatedPolicyAcaFfmId: 'AssociatedPolicyAcaFfmId',
    AssociatedPolicyName: 'AssociatedPolicyName',

    AssociatedTaskId: 'AssociatedTaskId',
    AssociatedTaskName: 'AssociatedTaskName',

    CreatedById: 'CreatedById',
    CreatedBy: 'CreatedBy',
    CreatedOn: 'CreatedOn',
}
export const EAttachmentFieldLabel = {
    ...EAttachmentField,
    ...{
        ContentType: 'Type',

        AssociatedTaskName: 'Task Name',

        AssociatedPolicyName: 'Policy Name',
        AssociatedPolicyAcaFfmId: 'Policy ACA FFM ID',

        TargetUserFullName: 'Contact Name',
        TargetUserNickName: 'Contact Nick',
        TargetUserDOB: 'Contact DOB',
        TargetUserSsn4: 'Contact SSN',

        CreatedBy: 'Created By',
        CreatedOn: 'Created On',
    }
}

export const ELogEntryFields = {
    Id: 'Id',
    CreatedById: 'CreatedById',
    CreatedBy: 'CreatedBy',
    CreatedOn: 'CreatedOn',

    Note: 'Note',
    Details: 'Details',

    AffPolicyId: 'AffPolicyId',
    AffPolicyName: 'AffPolicyName',
    AffPolicyAcaFfmId: 'AffPolicyAcaFfmId',

    AffAppUserId: 'AffAppUserId',
    AffAppUser: 'AffAppUser',

    AffTaskId: 'AffTaskId',
    AffTaskName: 'AffTaskName',

    AffAddressId: 'AffAddressId',
    AffAddress: 'AffAddress',

    AffUserId: 'AffUserId',

    TargetUserFullName: 'TargetUserFullName',
    TargetUserNickName: 'TargetUserNickName',
    TargetUserDOB: 'TargetUserDOB',
    TargetUserSsn4: 'TargetUserSsn4',
}
export const ELogEntryFieldLabels = {
    ...ELogEntryFields,
    ...{
        CreatedBy: 'Performed By',
        CreatedOn: 'Time',
        ContentType: 'Type',

        AffPolicyName: 'Policy Name',
        AffPolicyAcaFfmId: 'Policy FFM ID',

        AffAppUser: 'User',
        AffTaskName: 'Task',
        AffAddress: 'Address',

        TargetUserFullName: 'Contact Name',
        TargetUserNickName: 'Contact Nick',
        TargetUserDOB: 'Contact DOB',
        TargetUserSsn4: 'Contact SSN',
    }
}

export const EAppUserField = {
    Id: 'Id',
    UserName: 'UserName',

    Name: 'Name',
    Role: 'Role',
    NPN: 'NPN',
    Email: 'Email',
    PhoneNumber: 'PhoneNumber',

    EmailConfirmed: 'EmailConfirmed',

    LockoutEnabled: 'LockoutEnabled',
    LockoutEnd: 'LockoutEnd'
}
export const EAppUserFieldLabel = {
    ...EAppUserField,
    ...{
        UserName: 'Username',
        PhoneNumber: 'Phone',

        LockoutEnabled: 'Lockout Enabled',
        LockoutEnd: 'Lockout End',

        EmailConfirmed: 'Email Confirmed'
    }
}
export const AppUserFieldInfos = {
    [EAppUserField.Name]: { name: EAppUserField.Name, label: EAppUserFieldLabel.Name, required: true, length: 50 },
    [EAppUserField.UserName]: { name: EAppUserField.UserName, label: EAppUserFieldLabel.UserName, required: true, length: 256 },
    [EAppUserField.NPN]: { name: EAppUserField.NPN, label: EAppUserFieldLabel.NPN, length: 32 },
    [EAppUserField.Email]: { name: EAppUserField.Email, label: EAppUserFieldLabel.Email, required: true, length: 256 },
    [EAppUserField.PhoneNumber]: { name: EAppUserField.PhoneNumber, label: EAppUserFieldLabel.PhoneNumber },
    [EAppUserField.EmailConfirmed]: { name: EAppUserField.EmailConfirmed, label: EAppUserFieldLabel.EmailConfirmed },
}

export const EElementField = {
    Id: 'Id',
    Name: 'Name'
}
export const EElementFieldLabel = {
    ...EElementField,
    ...{
        Id: 'Designator'
    }
}
export const EElementFieldInfos = {
    [EElementField.Id]: { name: EElementField.Id, label: EElementFieldLabel.Id, required: true, length: 200 },
    [EElementField.Name]: { name: EElementField.Name, label: EElementFieldLabel.Name, required: true, length: 200 },
}

export const ESettingField = {
    Policy_Processor: 'Policy_Processor',
    Policy_Monitor: 'Policy_Monitor',
    Policy_Verifier: 'Policy_Verifier',
    Office_Hours: 'Office_Hours',
    After_Hour_Users: 'After_Hour_Users',

}

export const ESettingFieldLabel = {
    [ESettingField.Policy_Processor]: 'Processor',
    [ESettingField.Policy_Monitor]: 'Monitor',
    [ESettingField.Policy_Verifier]: 'Verifier',
    [ESettingField.Office_Hours]: 'Office Hours',
    [ESettingField.After_Hour_Users]: 'After Hour Users',
}

export const NewPerson = () => {

    return {
        [EPersonFields.Id]: uuid(),
        [EPersonFields.FirstName]: null,
        [EPersonFields.LastName]: null,
        [EPersonFields.MiddleName]: null,
        [EPersonFields.Suffix]: null,
        [EPersonFields.NickName]: null,
        [EPersonFields.Company]: null,
        [EPersonFields.PhoneNumber]: null,
        [EPersonFields.Email]: null,
        [EPersonFields.DOB]: null,
        [EPersonFields.Gender]: null,
        [EPersonFields.MaritalStatus]: null,
        [EPersonFields.DriverLicense]: null,
        [EPersonFields.ImmigrationStatus]: null,
        [EPersonFields.Ssn4]: null,
        [EPersonFields.SsnHash]: null,
        [EPersonFields.IsPolicyOwner]: false,
        [EPersonFields.Addresses]: [],
    };
}


export const NewUserAddress = (type, ownerId) => {
    return {
        [EUserAddressFields.Id]: uuid(),
        [EUserAddressFields.Type]: type,
        [EUserAddressFields.UserId]: ownerId,
    };
};

export const NewUserRel = (id, userId, relatedUserId, relType = undefined) => {
    return {
        [EPersonRelFields.Id]: id || uuid(),
        [EPersonRelFields.UserId]: userId,
        [EPersonRelFields.RelatedUserId]: relatedUserId,
        [EPersonRelFields.RelType]: relType
    };
};

export const NewUserNote = (userId, note) => {
    return {
        [EUserNoteField.Id]: uuid(),
        [EUserNoteField.UserId]: userId,
        [EUserNoteField.CreatedById]: undefined, //set by server
        [EUserNoteField.CreatedOn]: moment(), //set by server
        [EUserNoteField.Note]: note,
    }
}

export const NewPolicyMemberDto = (membershipId, policyId, person, rels, apply = true) => {
    let m = {
        ...person,
        ...{
            [EPolicyMemberFields.Id]: person.Id,
            [EPolicyMemberFields.MemberId]: membershipId || uuid(),
            [EPolicyMemberFields.PolicyId]: policyId,
            [EPolicyMemberFields.Apply]: apply,
            [EPersonFields.MaritalStatus]: person[EPersonFields.MaritalStatus] || EMartialStatus.Single,
        }
    };
    return m;
};

export const NewTask = (id, creatorId, policyId, userId, assigneeId) => {
    return {
        [ETaskFields.Id]: id || uuid(),
        [ETaskFields.Name]: undefined,
        [ETaskFields.Note]: undefined,
        [ETaskFields.IsOpen]: true,
        [ETaskFields.CompletedOn]: undefined,
        [ETaskFields.CreatedOn]: undefined,
        [ETaskFields.PolicyId]: policyId,
        [ETaskFields.UserId]: userId,
        [ETaskFields.AssigneeId]: assigneeId,
        [ETaskFields.Assignee]: undefined,
    };
};

export const NormalizeTextEditorValue = (value) => {
    let val = undefined;
    if (T.IsDefined(value)) {

        let s = value.trim();
        if (s.length > 0) {
            val = s;
        }
    }
    return val;
}

export const NormalizeSsn = (user, tableView = false) => {

    const fieldSsn = EPersonFields.SsnHash;
    const fieldSsn4 = EPersonFields.Ssn4;

    const ssn = user[fieldSsn];
    if (T.IsDefined(ssn)) {
        user[fieldSsn] = FormatSsn(ssn); //add '-' delimiter

        var ssn4 = ssn.slice(5, 10);
        ssn4 = ssn4.replaceAll('#', '').length === 0
            ? ''
            : tableView ?
                ssn4
                : `###-##-${ssn4}`;
        user[fieldSsn4] = ssn4;
    }
    return user[fieldSsn4];
};

export const DenormalizeAndValidateSsn = (user) => {

    let s = user[EPersonFields.SsnHash];
    if (T.IsDefined(s)) {
        s = s.trim();
        s = s.replaceAll('-', '');
        s = s.replaceAll('#', '*');

        if (s.length !== 9) {
            return 'Field is invalid';
        }
        else {
            user[EPersonFields.SsnHash] = s;
            user[EPersonFields.Ssn4] = s.slice(5, 10);
        }
    }
    else {
        user[EPersonFields.Ssn4] = '';
        user[EPersonFields.SsnHash] = '';
    }
    return undefined;
};

export const NormalizePolicyMember = (policyOwnerId, member, rels) => {
    let relId = uuid();
    let relType = null;

    if (member.Id === policyOwnerId) {
        relType = ERelationshipType.Self;
    }
    else if (T.IsArrayNonEmpty(rels)) {
        const rel = rels.find(x => x[EPersonRelFields.RelatedUserId] === member.Id);
        if (T.IsDefined(rel)) {
            relId = rel.Id;
            relType = rel[EPersonRelFields.RelType];
        }
    }
    member.RelId = relId;
    member[EPersonRelFields.RelType] = relType;
    return member;
}

export const DenormalizePolicyMember = (ownerId, entry, refRels) => {
    const relId = entry.RelId;
    const relType = entry[EPersonRelFields.RelType];
    const userId = entry.Id;
    refRels.push(NewUserRel(relId, ownerId, userId, relType));

    const m = { ...entry };
    delete m[EPersonRelFields.RelType];
    return m;
}

export const NewPolicyNote = (policyId, note) => {
    return {
        [EPolicyNoteFields.Id]: uuid(),
        [EPolicyNoteFields.PolicyId]: policyId,
        [EPolicyNoteFields.CreatedById]: undefined, //set by server
        [EPolicyNoteFields.CreatedOn]: moment(), //set by server
        [EPolicyNoteFields.Note]: note,
    }
}