import './workflow_state.css';
import * as T from './TypeUtil';
import Moment from 'moment';
import { Badge } from 'reactstrap';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { renderButton } from './EditorInputUtil';
import { INVALID_BIRTH_YEAR } from '../AppConstants';

const DATE_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'hh:mm:ss';
const DTG_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

//// DATETIME
export function FormatDate(dt) {
    return T.IsDefined(dt) ? Moment(dt).format(DATE_FORMAT) : dt;
}
export function GetAge(dt) {
    const yy = GetYear(dt);
    if (yy === INVALID_BIRTH_YEAR) return;
    const years = T.IsDefined(dt) ? Moment().diff(Moment(dt), 'years', false) : dt;
    return years;
}

export function FormatDateTime(dt) {
    return T.IsDefined(dt) ? Moment(dt).format(DTG_FORMAT) : dt;
}

export function FormatTime(dt) {
    return T.IsDefined(dt) ? Moment(dt).format(TIME_FORMAT) : dt;
}

export function GetYear(dt) {
    return T.IsDefined(dt) ? Moment(dt).year() : undefined;
}

export function ToUTCString(t) {
    const s = t
        ? T.IsString(t)
            ? new Date(t).toUTCString()
            : t.toUTCString()
        : '';
    return s;
}

////// PHONE

const PhoneRegEx = new RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);

export function FormatPhone(value) {
    if (!T.IsDefined(value)) return value;

    value = value.trim();
    var results = PhoneRegEx.exec(value);
    if (results !== null && results.length > 8) {

        const sExtension = (typeof results[8] !== 'undefined' ? ' x' + results[8] : '');
        return results[3] + '-' + results[4] + '-' + results[5] + sExtension;
    }
    else {
        return value;
    }
}
export function FormatPhoneMasked(rowId, value) {
    if (!T.IsDefined(value)) return value;

    value = value.trim();
    var results = PhoneRegEx.exec(value);
    if (results !== null && results.length > 8) {

        const sExtension = (typeof results[8] !== 'undefined' ? ' x' + results[8] : '');
        const sPhone = results[3] + '-' + results[4] + '-' + results[5] + sExtension;
        const last4 = results[5];

        return (
            <div>
                <span title={sPhone}>{last4}</span>
                {
                    renderButton(`${rowId}-btnPhoneCopy`, `Copy: ${sPhone}`,
                        () => { navigator.clipboard.writeText(value); },
                        false, 'mr-2 me-2', faCopy)
                }
            </div>
        );
    }
    else {
        return value;
    }
}
export function FormatLongString(value, length = 15) {
    if (!T.IsDefined(value)) return value;

    return value.length <= length
        ? value
        : <span title={value}>{value.substring(0, length)}</span>;
}

////// POLICY STATE
const EPolicyStateColor = {
    Draft: 'warning',
    NeedQuote: 'info',
    ToDo: 'primary',
    InProgress: 'primary',
    ThreeWayCalling: 'primary',
    Active: 'success',
    DoNotRenew: 'secondary',
    Cancelled: 'dark',
    Terminated: 'dark'
};

export function FormatPolicyState(value) {
    const c = EPolicyStateColor[value] || 'light';
    return <Badge color={c}>{value}</Badge>;

}
////// POLICY PRORITY
const EPolicyPriorityColor = {
    High: 'danger',
    Medium: 'primary',
    Low: 'secondary',
};
const EPolicyPriorityLabel = {
    High: 'H',
    Medium: 'M',
    Low: 'L',
};

export function FormatPolicyPriority(value) {
    const c = EPolicyPriorityColor[value] || EPolicyPriorityColor.Low;
    return <Badge color={c}>{EPolicyPriorityLabel[value] || value}</Badge>;

}

////// 

export const stringComparer = (a, b) => {
    const aName = a.Name || a.name;
    const bName = b.Name || b.name;
    if (aName < bName) {
        return -1;
    }
    if (aName > bName) {
        return 1;
    }
    return 0;
};

///// COLORS

export const EColor = {
    Primary: 'primary',
    Secondary: 'secondary',
    Success: 'success',
    Danger: 'danger',
    Warning: 'warning',
    Info: 'info',
    Light: 'light',
    Dark: 'dark',
    White: 'white',
    Progress: 'progress',
    Minor: 'minor',
};

export const ETextColor = {
    Primary: EColor.Light,
    Secondary: EColor.Light,
    Success: EColor.Light,
    Danger: EColor.Light,
    Warning: EColor.Dark,
    Info: EColor.Light,
    Light: EColor.Dark,
    Dark: EColor.Light,
    White: EColor.Dark,
    Progress: EColor.Light,
    Minor: EColor.Dark,
}

export const EColorComplement = {
    Primary: EColor.Light,
    Secondary: EColor.Light,
    Success: EColor.Light,
    Danger: EColor.Light,
    Warning: EColor.Dark,
    Info: EColor.Light,
    Light: EColor.Dark,
    Dark: EColor.Light,
    White: EColor.Dark,
    Progress: EColor.Light,
    Minor: EColor.Dark,
};
const COLORS = Object.values(EColor);

export const GetBootTextColorClass = (color) => {

    const colorClass = T.IsDefined(color) && COLORS.includes(color) ? 'text-' + color : undefined;
    return colorClass;
}
export const GetBootTableColorClass = (color) => {

    const colorClass = T.IsDefined(color) && COLORS.includes(color) ? 'table-' + color : undefined;
    return colorClass;
}
/** add '-' delimiter */
export const FormatSsn = (ssn) => {
    return T.IsDefined(ssn) ? ssn.slice(0, 3) + '-' + ssn.slice(3, 5) + '-' + ssn.slice(5, 10) : '';
}

export class RgbConverter {
    constructor() {
        this.min = 0;
        this.max = 16777215;

        this.bit16 = 16;
        this.bit8 = 8;

        this.maxRgb = 255;
        this.minRgb = 0;
    }

    rgbString(rgb) {
        let string = '';
        if (typeof rgb.r == 'number') {
            string += `${rgb.r}, `;
        } else string += `${this.minRgb}, `;

        if (typeof rgb.g == 'number') {
            string += `${rgb.g}, `;
        } else string += `${this.minRgb}, `;

        if (typeof rgb.b == 'number') {
            string += `${rgb.b}`;
        } else string += `${this.minRgb}, `;

        return string;
    }

    isDark(rgb) {
        if (typeof rgb.r == 'number'
            && typeof rgb.g == 'number'
            && typeof rgb.b == 'number'
        ) {
            let HSP = Math.sqrt(
                0.299 * (rgb.r * rgb.r) +
                0.587 * (rgb.g * rgb.g) +
                0.114 * (rgb.b * rgb.b)
            );

            if (HSP > 127.5) {
                return false;
            }
        }

        return true;
    }

    intToRgb(integer) {
        integer = typeof integer === 'string' ? parseInt(integer) : integer;

        var r = this.minRgb;
        var g = this.minRgb;
        var b = this.minRgb;

        if (typeof integer !== 'number'
            || Math.floor(integer) !== integer
            || integer < this.min
            || integer > this.max
        ) {
            // error
            return { r, g, b };
        }

        b = integer >> this.bit16;
        g = (integer - (b << this.bit16)) >> this.bit8;
        r = integer - (b << this.bit16) - (g << this.bit8);

        return { r, g, b };
    }

    rgbToInt(r, g, b) {
        return (
            ((r & 0x0ff) << this.bit16) |
            ((g & 0x0ff) << this.bit8) |
            (b & 0x0ff)
        );
    }
}

