
import { objectToSelectOptions } from './utils/EditorInputUtil';

export const EYesNo = {
    YES: 'Y',
    NO: 'N'
};

export const USER_SYSTEM = 'SYSTEM';

export const EYesNoOptions = [{ Id: 1, Name: 'Y' }, { Id: 0, Name: 'N' }];

export const ERoleValue = {
    Unknown: 1,
    User: 2,
    Agent: 3,
    Caller: 4,
    Processor: 5,
    Monitor: 6,
    Manager: 7,
    Administrator: 8,
    Superuser: 99,
};

export const ERole = {
    Unknown: 'Unknown',
    User: 'User',
    Agent: 'Agent',
    Caller: 'Caller',
    Processor: 'Processor',
    Monitor: 'Monitor',
    Manager: 'Manager',
    Administrator: 'Administrator',
    Superuser: 'Superuser',
};
export const ERoleOptions = objectToSelectOptions(ERole);
export const getRoleOptions = (loginRole) => {

    const idx = ERoleOptions.findIndex((x) => x.Id === loginRole);
    const options = idx > 0
        ? ERoleOptions.slice(0, idx)
        : [];
    return options;
};

export const EAddressType = {
    Home: 'Home',
    Mailing: 'Mailing',
};
export const EAddressTypeOptions = objectToSelectOptions(EAddressType);

export const EGender = {
    M: 'M',
    F: 'F',
};
export const EGenderOptions = objectToSelectOptions(EGender);

export const EMartialStatus = {
    Single: 'Single',
    Married: 'Married',
};
export const EMartialStatusOptions = objectToSelectOptions(EMartialStatus);

export const EImmigrationStatus = {
    US: 'US',
    GC: 'GC',
    VISA: 'VISA'
};
export const EImmigrationStatusOptions = objectToSelectOptions(EImmigrationStatus);

export const EPolicyPriority = {
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
};
export const EPolicyPriorityOptions = objectToSelectOptions(EPolicyPriority);

export const EPolicyCategory = {
    Health: 'Health',
    Individual: 'Individual',
    Medicare: 'Medicare',
    PC: 'PC',
    Commercial: 'Commercial',
};

export const EPolicyCategoryLabels = {
    ...EPolicyCategory,
    ...{
        PC: 'Property & Casualty',
    }
};
export const EPolicyCategoryOptions = objectToSelectOptions(EPolicyCategory, EPolicyCategoryLabels);

export const EPolicyType = {
    ACA: 'ACA',
    Dental: 'Dental',
    Medical: 'Medical',
    Medicare: 'Medicare',
    Vision: 'Vision',

    Life: 'Life',
    Annuity: 'Annuity',
    Disability: 'Disability',
    LTC: 'LTC',

    GL: 'GL',
    ProductLiability: 'ProductLiability',
    ProfessionalLiability: 'ProfessionalLiability',
    WC: 'WC',

    Auto: 'Auto',
    Property: 'Property',
    Umbrella: 'Umbrella'
};

export const EPolicyTypeLabels = {
    ...EPolicyType,
    ...{
        ProductLiability: 'Product Liability',
        ProfessionalLiability: 'Professional Liability',
    }
};

export const EPolicyTypeOptions = objectToSelectOptions(EPolicyType, EPolicyTypeLabels);

export const EPolicyState = {
    Draft: 'Draft',
    NeedQuote: 'NeedQuote',
    ToDo: 'ToDo',
    InProgress: 'InProgress',
    ThreeWayCalling: 'ThreeWayCalling',
    Active: 'Active',
    DoNotRenew: 'DoNotRenew',
    Cancelled: 'Cancelled',
    Terminated: 'Terminated'
};

export const EPolicyStateLabels = {
    ...EPolicyState,
    ...{
        Draft: 'Draft (Need Information)',
        NeedQuote: 'Need Quote',
        ToDo: 'To Do (Ready to Work)',
        InProgress: 'In-Progress',
        ThreeWayCalling: 'Three-Way Calling',
        DoNotRenew: 'Do Not Renew',
    }
};
export const EPolicyStateOptions = objectToSelectOptions(EPolicyState, EPolicyStateLabels);

export const ECommissionType = {
    Monthly: 'Monthly',
    OneTime: 'OneTime',
    Yearly: 'Yearly'
};

export const ECommissionTypeLabels = {
    ...ECommissionType,
    ...{
        OneTime: 'One Time',
    }
};
export const ECommissionTypeOptions = objectToSelectOptions(ECommissionType, ECommissionTypeLabels);

export const ERelationshipType = {
    Self: 'Self',
    Spouse: 'Spouse',
    Child: 'Child',
    Sibling: 'Sibling',
    Parent: 'Parent',
    NieceNephew: 'NieceNephew',
    Cousin: 'Cousin',
    Grandchild: 'Grandchild',
    Grandparent: 'Grandparent',
    AuntUncle: 'AuntUncle',
    Friend: 'Friend',
    Relative: 'Relative',
};

export const ERelationshipTypeLabels = {
    ...ERelationshipType,
    ...{
        NieceNephew: 'Niece/Nephew',
        AuntUncle: 'Aunt/Uncle',
    }
};
export const ERelationshipTypeOptions = objectToSelectOptions(ERelationshipType, ERelationshipTypeLabels);

export const EPrivilege = {

    CreateTask: 'CreateTask',
    ViewTask: 'ViewTask',

    CanRefer: 'CanRefer',
    ViewReferral: 'ViewReferral',
    ManageReferral: 'ManageReferral',

    SubmitPolicy: 'SubmitPolicy',
    ViewPolicy: 'ViewPolicy',
    ManagePolicy: 'ManagePolicy',

    ProcessPolicy: 'ProcessPolicy',
    ManageProcessor: 'ManageProcessor',

    VerifyPolicy: 'VerifyPolicy',
    ManageVerifier: 'ManageVerifier',

    ViewClient: 'ViewClient',
    ManageClient: 'ManageClient',

    ManageAddress: 'ManageAddress',
    ManageUsers: 'ManageUsers',

    ViewLog: 'ViewLog',
    ManageLog: 'ManageLog',
}